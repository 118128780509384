import * as React from "react";
import "../styles/style.scss";
import Me from "../images/me2.jpeg";
import Berlin from "../images/berlin.svg";
import PrEPtimeIcon from "../images/preptime-icon.svg";
import PrEPtimeLogo from "../images/preptime-logo.svg";
import PrEPtimeScreenshots from "../images/preptime-screenshots.png";
import iOSIcon from "../images/apple.svg";
import AndroidIcon from "../images/android.svg";
import LinkedinIcon from "../images/linkedin-icon.svg";
import GithubIcon from "../images/github-icon.svg";

// markup
const IndexPage = () => {
  return (
    <main>
      <title>Gal Koren - Frontend Software Engineer</title>
      <div
        data-center-items="true"
        data-sal="slide-down"
        data-sal-easing="ease"
        data-sal-duration="1000"
      >
        <div data-flex="static" data-dir="row" data-center-items="true">
          <div data-flex="static" data-padded="true">
            <img src={Me} alt="Profile picture" id="profile-pic" />
          </div>
          <div data-flex="static" data-padded="true" id="titles">
            <h1 data-flex="static">
              <strong>Gal</strong> Koren
            </h1>
            <h2 data-flex="static">
              front-end software engineer developing web and mobile
              applications.
            </h2>
          </div>
        </div>
      </div>
      <div data-sal="fade" data-sal-easing="ease" data-sal-duration="1000">
        <div>
          <p id="bio">
            I currently work at the data analytics company{" "}
            <a href="https://www.alteryx.com/" title="Alteryx">
              Alteryx
            </a>
            {" "}
            (previously {" "}
            <a href="https://www.trifacta.com/" title="Trifacta">
              Trifacta
            </a>,{" "}
            until its acquisition 2022) where I’ve developed key customer-facing features, such as our
            orchestration and parameterization tools. On the side I also build mobile
            applications, usually out of my own necessity. Previously, I
            completed my B.Sc. and M.Eng. at{" "}
            <a href="https://www.mit.edu/" title="MIT">
              MIT
            </a>
            , where I worked as a graduate researcher at the{" "}
            <a href="https://www.media.mit.edu/" title="MIT Media Lab">
              MIT Media Lab
            </a>{" "}
            Social Computing Group. For my thesis I developed child-adapted
            wearable devices for physiological sensing and analyzed data to help
            advance the understanding of early childhood development.
          </p>
        </div>
        <div data-center-items="true">
          <div data-dir="row" id="location-container" data-flex="static">
            <div
              data-flex="static"
              style={{
                marginRight: "8px",
              }}
            >
              <img src={Berlin} />
            </div>
            <div data-flex="static">
              <p>
                I am based in Berlin, Germany.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div
        id="project-highlight-container"
        data-sal="slide-up"
        data-sal-easing="ease"
        data-sal-duration="1000"
      >
        <h3>Project highlight</h3>
        <a
          href="https://preptimeapp.com/"
          title="PrEPtime"
          style={{
            textDecoration: "none",
          }}
        >
          <div
            style={{
              backgroundColor: "#EDE7E4",
              borderRadius: 5,
              padding: "8px",
              position: "relative",
            }}
          >
            <div data-dir="row">
              <img
                src={PrEPtimeIcon}
                width={45}
                style={{
                  borderRadius: 5,
                  marginRight: "8px",
                }}
              />
              <img src={PrEPtimeLogo} width={90} />
            </div>
            <p id="preptime-description">
              Pill reminder app for HIV-preventing medication
            </p>
            <img id="preptime-screenshots" src={PrEPtimeScreenshots} />
            <div data-center-items="true" id="mobile-os-icon-container">
              <img
                src={iOSIcon}
                width={15}
                style={{
                  marginBottom: "8px",
                }}
              />
              <img src={AndroidIcon} width={22} />
            </div>
          </div>
        </a>
      </div>
      <div
        data-center-items="true"
        style={{
          marginTop: "64px",
          opacity: 0.5,
        }}
      >
        <div data-dir="row">
          <a
            style={{
              marginRight: "32px",
            }}
            href="https://www.linkedin.com/in/gal-koren-2a28b7125/"
            title="Linkedin"
          >
            <img src={LinkedinIcon} width={25} />
          </a>
          <a href="https://github.com/galkn" title="Github">
            <img src={GithubIcon} width={25} />
          </a>
        </div>
        <p data-small-font="true">Site built with Gatsby.</p>
      </div>
    </main>
  );
};

export default IndexPage;
